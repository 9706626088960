import Vue from 'vue';
import Router from 'vue-router';

const routes = require('./routes').default;

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: 'active',
  linkExactActiveClass: 'exact-active',
  routes,
  scrollBehavior(to, _from, savedPosition) {
    switch (true) {
      case to.hash:
        return { selector: to.hash };

      case savedPosition:
        return savedPosition;

      case to.meta.scrollToTop !== false:
        return { x: 0, y: 0 };

      default:
        return null;
    }
  },
});

export default router;
