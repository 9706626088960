import {
  chain, first, values,
} from 'underscore';

import sites from '~config/sites';
import { Config } from '~model';

/*
 * Get the current site-config from sites.js.
 *
 * Search insides the domains prop of each site object and
 * get the match with the current window location hostname.
 */
export function getCurrentSiteConfig(
  inheritedSites = sites,
): Config {
  const { hostname, pathname } = window.location;

  const mappedSites = chain(inheritedSites)
    .map((
      site: Config,
      key: string,
    ) => {
      site.id = key;
      site.siteName = site.name;
      return site;
    });

  const domainSites = mappedSites
    .filter((site: Config) => site.domains.includes(hostname))
    .value();

  const pathSites = domainSites
    .filter((site: Config) => site.pathnames?.find((e) => e.test(pathname)));

  return first(values(pathSites))
    || first(values(domainSites))
    || first(values(inheritedSites));
}

/*
 * Get the current site config as const object
 */
export const currentSite = getCurrentSiteConfig();
