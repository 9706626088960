import '@/assets/app.scss';

import VueGtm from '@gtm-support/vue2-gtm';
import { AxiosResponse } from 'axios';
import { BootstrapVue } from 'bootstrap-vue';
import { createPinia, PiniaVuePlugin } from 'pinia';
import { VueMaskDirective } from 'v-mask';
import Vue from 'vue';
import VueMeta from 'vue-meta';

import parameterApi from '~api/parameter';
import App from '~component/App.vue';
import i18n from '~i18n';
import flashMessage from '~plugin/flash-message/install';
import FontAwesome from '~plugin/font-awesome';
import VueSanitize from '~plugin/sanitize';
import router from '~router';
import store from '~store';

import { currentSite } from './helpers/currentSite';

declare global {
  interface Window {
    grecaptcha: {
      reset: () => void;
    };
    validateRecaptcha: () => void;
    paypal: {
      Buttons: ({
        fundingSource,
        createOrder,
        onApprove,
      }: {
        fundingSource: string;
        createOrder: () => unknown;
        onApprove: (data: {
          orderID: string;
        }) => unknown;
      }) => {
        render: (elem: string) => void;
      };
      FUNDING: {
        PAYPAL: string;
      };
    };
  }
}

Vue.config.productionTip = false;

Vue.use(flashMessage);
Vue.use(BootstrapVue);
Vue.use(VueMeta);
Vue.directive('mask', VueMaskDirective);

Vue.use(PiniaVuePlugin);
const pinia = createPinia();

// Google TagManager
// **IMPORTANT:**
// needs to be setup in Google GUI with CookieFirst to comply with cookie settings:
// https://support.cookiefirst.com/hc/en-us/articles/360011453957-Google-Tag-Manager-cookie-banner-configuration
const googleTagManagerId = store?.getters?.getGoogleTagManagerId;
Vue.use(VueGtm, {
  id: googleTagManagerId,
  defer: false,
  compatibility: true,
  // toggle in component with this.$gtm.enabled(false)
  enabled: parseInt(process.env.VUE_APP_GTM_ACTIVE, 10) && googleTagManagerId,
  debug: false,
  loadScript: true,
  vueRouter: router,
  // ignoredViews: ["homepage"], // Don't trigger events for specified router names (case insensitive) (optional)
  trackOnNextTick: false,
});

// add cookiefirst script as first element in body
if (parseInt(process.env.VUE_APP_COOKIE_FIRST_ACTIVE, 10)) {
  const script = document.createElement('script');
  script.setAttribute('src', 'https://consent.cookiefirst.com/banner.js');
  script.setAttribute('data-cookiefirst-key', store?.getters?.getCookieFirstSiteKey);
  document?.querySelector('body')?.prepend(script);
}

// append queuefair script to head
if (parseInt(process.env.VUE_APP_QUEUE_FAIR_ACTIVE, 10)) {
  parameterApi.getPublic().then((response: AxiosResponse) => {
    if (response.data['queuefair.redeem_active']) {
      const script2 = document.createElement('script');
      script2.setAttribute('src', 'https://files.queue-fair.net/queue-fair-adapter.js');
      script2.setAttribute('data-queue-fair-client', 'wunschgutschein');
      script2.setAttribute('data-cookiefirst-category', 'necessary');
      document?.querySelector('head')?.append(script2);
    }
  });
}

new Vue({
  router,
  i18n,
  store,
  pinia,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment, vue/padding-lines-in-component-definition
  // @ts-ignore:next-line
  VueSanitize,

  beforeCreate() {
    store.dispatch('initApiConfig');
    store.dispatch('initExternalCssFile');
    store.dispatch('initLanguage');
    store.dispatch('initRouter');

    const { layout } = currentSite;

    document.body.setAttribute('layout', layout || 'classic');
  },

  render: (h) => h(App),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment, vue/padding-lines-in-component-definition
  // @ts-ignore:next-line
  FontAwesome,
}).$mount('#app');
