import { RemovableRef, useLocalStorage } from '@vueuse/core';
import { AxiosResponse } from 'axios';
import { Dispatch } from 'vuex/types/index.d';

import api from '~api/api';
import jwt from '~helper/jwt';
import { n } from '~i18n';
import {
  VoucherCategoryId,
  VoucherRedeem,
  VoucherRedeemTokenPayload,
} from '~model';

declare global {
  interface Window {
    voucherTokenTimeout: number;
    amazon: {
      Pay: {
        renderButton: (element: string, parameters: object) => void;
      };
    };
  }

  interface Getters {
    getVoucher: VoucherRedeem;
    getVoucherPrepaid: boolean;
    getVoucherCurrency: string;
    getVoucherPrice: number;
    getVoucherPartner: string;
    getVoucherDistribution: string;
    getVoucherValue: number;
    getVoucherBalanceInCent: number;
    getVoucherCategoryId: VoucherCategoryId;
    getVoucherToken: string;
    getVoucherTokenPayload: VoucherRedeemTokenPayload;
    getExpirationTime: number;
  }
}

export interface State {
  voucher: RemovableRef<VoucherRedeem | null> | VoucherRedeem | null;
  token: RemovableRef<string | null> | string | null;
  expires: RemovableRef<number | null> | number | null;
  prepaid: RemovableRef<boolean | null> | boolean | null;
}

export default {
  state: <State>{
    /* voucher object */
    voucher: useLocalStorage('voucher', <object | null>{}),

    /* voucher token */
    token: useLocalStorage('voucherToken', <string | null>null),

    /* timestamp of expiration date */
    expires: useLocalStorage('voucherTokenExpires', <number | null>null),

    /* indicate if prepaid voucher */
    prepaid: useLocalStorage('voucherPrepaid', false),
  },

  actions: {
    /*
     * Fetch the voucher token from API.
     * Send the code, category and captcha as parameters of the post-request.
     */
    async fetchVoucherToken(
      { dispatch }: { dispatch: Dispatch},
      {
        code,
        voucherCategory,
        captcha,
      }: {
        code: string;
        voucherCategory?: string;
        captcha?: string;
      },
    ) {
      return api
        .post('/v2/voucher/token', { code, voucherCategory, captcha })
        .then((response: AxiosResponse) => {
          dispatch('setVoucherToken', response.data?.token);
          dispatch('setVoucher');
        });
    },

    /*
     * Initialize the charge api-call for voucher.
     * Call with voucher token in header and the payment-provider in api-url.
     * Also send params containing amount and currency.
     */
    async initCharge(
      { state }: { state: State },
      {
        provider,
        params,
      }: {
        provider: string;
        params: object;
      },
    ) {
      return api.post(`/v2/voucher/charge/init/${provider}`, params, {
        headers: {
          'x-jwt-token': state.token,
        },
      });
    },

    /*
     * Finalize the charge api-call for voucher.
     * Call with voucher token in header and the payment-provider in api-url.
     * Also send params containing amount and currency.
     */
    async finalizeCharge(
      { state }: { state: State },
      { provider, params }: { provider: string; params: object },
    ) {
      return api.post(`/v2/voucher/charge/finalize/${provider}`, params, {
        headers: {
          'x-jwt-token': state.token,
        },
      });
    },

    /*
     * Initialize amazon-pay.
     */
    async initAmazonPay(
      { dispatch }: { dispatch: Dispatch },
      {
        amount,
        currency,
        returnUrl,
        redeemValue,
        ledgerCurrency,
        checkoutLanguage,
      } : {
        amount: string;
        currency: string;
        returnUrl: string;
        redeemValue: number;
        ledgerCurrency: string;
        checkoutLanguage: string;
      },
    ) {
      return dispatch('initCharge', {
        provider: 'amazonpay',
        params: { amount, currency, returnUrl },
      }).then((
        response: {
          data: {
            merchantId: string;
            productType: string;
            payload: string;
            buttonSignature: string;
            publicKeyId: string;
          };
        },
      ) => {
        // Response Variables
        const { data } = response;
        const { merchantId } = data;
        const { productType } = data;
        const payloadJSON = data.payload;
        const signature = data.buttonSignature;
        const { publicKeyId } = data;

        sessionStorage.setItem(
          'formData',
          JSON.stringify({
            amount,
            currency,
            redeemValue,
          }),
        );

        window.amazon.Pay.renderButton('#amazonPayButton', {
          merchantId,
          ledgerCurrency,
          checkoutLanguage,
          productType,
          placement: 'Other',
          presentmentCurrency: currency,
          // buttonColor: 'LightGray',
          createCheckoutSessionConfig: {
            payloadJSON,
            signature,
            publicKeyId,
          },
        });
      });
    },

    /*
     * Set the voucher to vuex state and local storage
     * from voucher token api call response.
     */
    setVoucher(
      {
        state,
        getters,
      }:
      {
        state: State;
        getters: Getters;
      },
      voucher = getters?.getVoucherTokenPayload?.voucher,
    ) {
      state.voucher = voucher;
    },

    /*
     * Remove the voucher from vuex state.
     */
    removeVoucher({ state }: { state: State }) {
      state.voucher = null;
    },

    /*
     * Set the voucher token and expiry date to state.
     */
    setVoucherToken(
      {
        state,
        getters,
      }: {
        state: State;
        getters: Getters;
      },
      token: string,
    ) {
      state.token = token;
      state.expires = getters.getVoucherTokenPayload.exp;
    },

    /*
     * Remove the current voucher token with expiration date.
     */
    removeVoucherToken({ state }: { state: State }) {
      state.token = null;
      state.expires = null;
      if (window?.voucherTokenTimeout) {
        clearTimeout(window.voucherTokenTimeout);
      }
    },

    /*
     * Get the status if the voucher token has expired.
     * If expiration time is set and greater than 0, execute inherited method.
     */
    whenTokenExpires(
      { getters }: { getters: Getters },
      action: () => void,
    ) {
      const expTime = getters.getExpirationTime;

      if (expTime) {
        const remainingSec = expTime - Math.floor(Date.now() / 1000);
        const offsetSec = 10;
        const timeoutSec = remainingSec - offsetSec;

        window.voucherTokenTimeout = setTimeout(() => {
          action();
        }, timeoutSec * 1000);
      }
    },

    /*
     * Remove the current voucher and voucher-token.
     */
    removeCurrentVoucherAndToken(
      { dispatch }: { dispatch: Dispatch },
    ) {
      dispatch('removeVoucher');
      dispatch('removeVoucherToken');
    },
  },

  getters: <GettersDefinition<State>> {
    /*
     * Get the voucher as object.
     */
    getVoucher: (state: State) => state.voucher,

    /*
     * Get the voucher price in human readable form.
     */
    getVoucherCurrency: (
      _state: State,
      getters: Getters,
    ) => getters.getVoucher?.currency,

    getVoucherPrepaid: (
      _state: State,
      getters: Getters,
    ) => getters.getVoucher?.isPrepaid,

    /*
     * Get the voucher price in human readable form.
     */
    getVoucherPrice: (
      _state: State,
      getters: Getters,
    ) => n(getters.getVoucherBalanceInCent / 100, 'currencyCompact'),

    /*
     * Get the voucher partner.
     */
    getVoucherPartner: (
      _state: State,
      getters: Getters,
    ) => getters.getVoucherTokenPayload?.thirdPartyRedeem?.partner || null,

    /*
     * Get the distribution from the current voucher.
     */
    getVoucherDistribution: (
      _state: State,
      getters: Getters,
    ) => getters.getVoucher?.distribution,

    /*
     * Get the voucher value or balance in cent.
     */
    getVoucherValue: (
      _state: State,
      getters: Getters,
    ) => getters.getVoucher?.balanceInCent,

    /*
     * Get the voucher value or balance in cent.
     * If undefined or null, get the value from sessionStorage.
     */
    getVoucherBalanceInCent: (
      _state: State,
      getters: Getters,
    ) =>
      getters.getVoucher?.balanceInCent ||
      sessionStorage?.getItem('voucherBalanceAfterRedeem'),

    /*
     * Get the voucher category id from the currentVoucherHelper,
     * or get it alternatively from config (voucherCategoryId).
     */
    getVoucherCategoryId: (
      _state: State,
      getters: Getters,
    ) =>
      getters.getVoucher?.categoryId ??
      getters.getConfig?.voucherCategory,

    /*
     * Get the voucher token from state.
     */
    getVoucherToken: (state: State) => state.token,

    /*
     * Get the voucher token payload.
     * Try to decode JWT Token, otherwise return empty object.
     */
    getVoucherTokenPayload: (
      _state: State,
      getters: Getters,
    ) => {
      const token = getters.getVoucherToken;
      return token ? jwt.decode(token)?.payload : {};
    },

    /*
     * Get the expiration time as numeric timestamp.
     */
    getExpirationTime: (state: State) => state.expires,
  },
};
