import _ from 'underscore';
import { Route } from 'vue-router';
import { Dispatch } from 'vuex';

import router from '~router';

declare global {
  interface Getters {
    getVariationRoutePath: string;
    getRouteCategories: [];
  }
}

export default {
  actions: {
    /*
     * Add a route to the vue-router.
     */
    addRoute({ getters }: { getters: Getters }) {
      router.addRoute('layout', {
        path: getters.getVariationRoutePath,
        name: 'redeem',
        component: () => import('@/pages/RedeemPage.vue'),
        props: true,
      });
    },

    /*
     * Init router. Set after each route which targets the 'redeem' (main) page.
     * Set category if parameters in URL has a category.
     */
    initRouter(
      { dispatch }:
      { dispatch: Dispatch },
    ) {
      router.afterEach((to: Route) => {
        if (to.name === 'redeem') {
          const { category } = to.params;
          if (category) {
            dispatch('setCategory', category);
          } else {
            dispatch('removeCategory');
          }
        }
      });
    },
  },

  getters: <GettersDefinition<unknown>> {
    /*
     * Get router path as string
     */
    getVariationRoutePath: (
      _state: unknown,
      getters: Getters,
    ) => `/:category(${getters.getRouteCategories.join('|')})?`,

    /*
     * Get route categories as compact and unique array
     */
    getRouteCategories: (
      _state: unknown,
      getters: Getters,
    ) =>
      _.chain([getters.getVariationUrl, getters.getCategory]).compact().unique().value(),
  },
};
