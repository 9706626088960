import { AxiosResponse } from 'axios';

import api from '~api/api';

export default {
  async getByKey(key: string): Promise<AxiosResponse> {
    return api.get(`/v2/parameter/${key}`);
  },
  async getPublic(): Promise<AxiosResponse> {
    return api.get('/v2/parameter');
  },
};
