import Vue from 'vue';
import VueI18n from 'vue-i18n';

import dateTimeFormats from '~i18n/dateTimeFormats';
import numberFormats from '~i18n/numberFormats';

Vue.use(VueI18n);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const i18n = <any> new VueI18n({
  fallbackLocale: process?.env?.VUE_APP_I18N_FALLBACK_LOCALE || 'de',
  numberFormats,
  dateTimeFormats,
  silentTranslationWarn: true,
});

export default i18n;

export function t(...args: unknown[]) {
  return i18n?.t(...args);
}

export function te(...args: unknown[]) {
  return i18n?.te(...args);
}

export function n(value: number, ...args: unknown[]) {
  return i18n?.n(value, ...args);
}
