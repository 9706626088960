import { AxiosError, AxiosResponse } from 'axios';
import _ from 'underscore';
import { Dispatch } from 'vuex/types/index.d';

import api from '~api/api';
import { n } from '~i18n';
import {
  ShopBase,
  ShopCategoriesCache,
  ShopCategory,
  ShopDetails,
  ShopDetailsCache,
  ShopId,
  ShopVoucherValues,
  ShopWall,
  ShopWallCache,
  VoucherCategoryId,
  VoucherRedeem,
} from '~model';

interface State {
  shopWall: {
    current: ShopWall;
    cache: ShopWallCache;
    loading: boolean;
  };

  categories: {
    current: ShopCategory[];
    cache: ShopCategoriesCache;
  };

  shopDetails: {
    id: ShopId;
    current: ShopDetails | object;
    cache: ShopDetailsCache;
  };
}

declare global {
  interface Getters {
    getShopWall: ShopWall;
    getShopWallCache: ShopWallCache;
    getShopWallLoading: boolean;
    getCategories: ShopCategory[];
    getCategoriesCache: ShopCategoriesCache;
    getShopDetails: ShopDetails;
    getShopDetailsCache: ShopDetailsCache;
    getShopDetailsId: ShopId;
    getShopDetailsFromShopWall: ShopDetails;
    getShopName: string;
    getShopPhysical: boolean;
    getRedeemables: string[];
    getVoucherValueLevels: [];
    getVoucherValueLevelsMapped: () => [];
    getVoucherValueLevelTitle: string;
    getVoucherValueLevelLabel: (val: number) => string;
  }
}

export default {
  state: <State> {
    /*
     * Define shopWall as an object
     * shopWall.current: array of objects (object containing informations about each shop)
     * Example:
     * [
     *    {id: 112, name: 'shopA'},
     *    {id: 113, name: 'shopB'},
     *    ...
     *    {id: 150, name: 'shopX'}
     * ]
     * shopWall.cache: object (each sub-object containing: cache-key => shopwall-array)
     * Example:
     * {
     *    {
     *      cache-MARKETING B2B-1500-EUR-voucherValues-cached: [
     *        {id: 112, name: 'shopA' },
     *        {id: 113, name: 'shopB' },
     *        ...
     *      ]
     *    },
     *    {
     *      cache-null-null-null-voucherValues-cached: [
     *        {id: 155, name: 'shopX' },
     *        {id: 159, name: 'shopY' },
     *        ...
     *      ]
     *    },
     *    ...
     * }
     */
    shopWall: {
      /* containing current visible shop-wall shops as objects */
      current: [],

      /* containing keyed shopwall objects containing shops */
      cache: {},

      /* if true, a new array of shopwall shops is loading */
      loading: false,
    },

    /*
     * Define categories
     * the same caching principle used in shopWall
     */
    categories: {
      /* containing current visible categories as objects */
      current: [],

      /* containing keyed category objects containing shops */
      cache: {},
    },

    /*
     * shopDetails contains additional shop-information
     * for a clicked interactive shop
     * shopDetails.id:      pointing for the current / last clicked shop in the interactive shopWall
     * shopDetails.current: contains information about the current shop
     * shopDetails.cache:   caching prinziple by shop-id for less api-calls
     */
    shopDetails: {
      /* containing the current active shop-details ID */
      id: null,

      /* containing the currently active shop-details */
      current: {},

      /* containing all cached shop-details objects cached by shopID */
      cache: {},
    },
  },

  actions: {
    /*
     * Set the Search-Strings for each shop. (search inside slug and name)
     * Lowercase and replace all the whitespace from search-string
     */
    setShopWallSearchStrings(
      {
        state,
      }: {
        state: State;
      },
    ): void {
      const trimmer = (s: string) => s?.toLowerCase()?.replace(/\s/g, '');

      _.map(state.shopWall.current, (shop: ShopBase) => {
        const name = trimmer(shop.name);
        const slug = trimmer(shop.slug);
        const keywords = trimmer(shop.keywords?.join('') || '');

        let searchString = name !== slug ? `${name}${slug}` : name;
        searchString += keywords;

        shop.search = searchString;
      });
    },

    /*
     * Fetch the already translated categories through cache or
     * api-call as an array of categories.
     */
    async fetchCategories(
      {
        state,
        getters,
      }: {
        state: State;
        getters: Getters;
      },
      voucherCategoryId: VoucherCategoryId,
    ) {
      const categories = getters?.getCategoriesCache?.[voucherCategoryId];

      if (categories) {
        state.categories.current = categories;
      } else {
        return api
          .get(`/v2/shop/categories/${voucherCategoryId}`)
          .then((response: AxiosResponse) => {
            state.categories.current = response.data;
            state.categories.cache[voucherCategoryId] = state.categories.current;
          })
          .catch((error: AxiosError) => error);
      }
      return null;
    },

    /*
     * Fetch the shop wall shops from cache or api-call as an array of shops.
     */
    async fetchShopWall(
      {
        state,
        dispatch,
        getters,
      }: {
        state: State;
        dispatch: Dispatch;
        getters: Getters;
      },
      data: AxiosResponse['data'],
    ) {
      const params = data?.params || {};
      const key = `cache-${_.reduce(params, (a: string, b: string) => `${a}-${b}`)}-cached`;
      const shops = getters?.getShopWallCache?.[key];

      if (shops) {
        state.shopWall.current = shops;
        dispatch('setShopWallSearchStrings');
        return null;
      }

      if (!getters.getShopWallLoading) {
        state.shopWall.loading = true;

        return api
          .get(`/v2/shop/wall/${data.id}`, { params })
          .then((response: AxiosResponse) => {
            state.shopWall.current = response.data;
            dispatch('setShopWallSearchStrings');
            state.shopWall.cache[key] = state.shopWall.current;
          })
          .catch((error: AxiosError) => error)
          .then(() => {
            state.shopWall.loading = false;
          });
      }

      return null;
    },

    /*
     * Fetch the shop-details from api by shop-id.
     * Set shop-details-id to mutation
     */
    async fetchShopDetails(
      {
        state,
        getters,
      }:{
        state: State;
        getters: Getters;
      },
      shopId: ShopId,
    ) {
      state.shopDetails.id = shopId;

      const params = {
        fields: [
          'name',
          'logo',
          'description',
          'redeemWarnings',
          'isNewShopCustomerInquiry',
          'codeTypes',
          'link',
          'voucherValues',
          'type',
        ].join(','),
        ...(getters?.getVoucherValue ? {
          voucherValue: getters.getVoucherValue,
        } : {}),
      };

      const key = `cache-${shopId}-${_.reduce(params, (a: string | number, b: string | number) => `${a}-${b}`)}-cached`;
      const shopDetailsFromCache = getters.getShopDetailsCache?.[key];
      if (shopDetailsFromCache) {
        state.shopDetails.current = shopDetailsFromCache;
        return state.shopDetails.current;
      }

      state.shopDetails.current = {};
      return api
        .get(`/v2/shop/${shopId}`, { params })
        .then((response: AxiosResponse) => {
          state.shopDetails.current = {
            ...response.data,
            hasDetails: true,
          };
          if (shopId) {
            state.shopDetails.cache[key] = state.shopDetails.current;
          }
        })
        .catch((error: AxiosError) => error);
    },

    /*
     * Clear the shop details. Should be called if the
     * Shop modal is closed for example.
     */
    clearShopDetails({ state }: { state: State }) {
      state.shopDetails.id = null;
      state.shopDetails.current = {};
    },
  },

  getters: <GettersDefinition<State>> {
    /*
     * Get the current shops from state.
     */
    getShopWall: (state: State) => state.shopWall?.current,

    /*
     * Get the cached shops from state by cache key
     */
    getShopWallCache: (state: State) => state.shopWall?.cache,

    /*
     * Get the state if the shopwall is currently loading from API.
     */
    getShopWallLoading: (state: State) => state.shopWall?.loading,

    /*
     * Get the categories from state.
     */
    getCategories: (
      state: State,
      getters: Getters,
    ) => state.categories?.current.filter((category: ShopCategory) => {
      switch (getters.getRedeemCampaign) {
        // Only on nissan and renault
        case 'renault':
        case 'nissan':
          // From "reisen" till "coaching"
          return ![24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35]
            .includes(category.id);

        // Default
        default:
          return category;
      }
    }),

    /*
     * Get the categories from state.
     */
    getCategoriesCache: (state: State) => state.categories?.cache,

    /*
     * Try to get the shop-details from shop-details current state,
     * or from shopwall (fallback / alternative).
     */
    getShopDetails: (
      state: State,
      getters: Getters,
    ) => (
      _.chain({
        ...getters?.getShopDetailsFromShopWall,
        ...state?.shopDetails?.current,
      }).mapObject((
        shop: ShopBase | unknown,
        key: string,
      ) => {
        switch (key) {
          case 'voucherValues':
            return _(shop).sortBy('valueInCent');
          default:
            return shop;
        }
      }).value()
    ),

    getShopName: (
      _state: State,
      getters: Getters,
    ) => getters.getShopDetails?.name || '',

    /*
     * Returns true if the shop type is physical and contains physical product(s)
     */
    getShopPhysical: (
      _state: State,
      getters: Getters,
    ) => getters.getShopDetails?.type === 'physical',

    /*
     * Get the cached shops from state by cache key
     */
    getShopDetailsCache: (state: State) => state.shopDetails?.cache,

    /*
     * Try to get the shop-details from shop-details current state,
     * or from shopwall (fallback / alternative).
     */
    getShopDetailsId: (state: State) => Number(state.shopDetails?.id),

    /*
     * Get the shops from getter cached shops or state.
     */
    getShopDetailsFromShopWall: (
      _state: State,
      getters: Getters,
    ) => _
      .findWhere(getters.getShopWall, {
        id: getters.getShopDetailsId,
      }),

    /*
     * Get (filter) redeemables by shops as array.
     * Example output: ['REDEEMABLE_ONLINE', 'REDEEMABLE_BRANCH']
     */
    getRedeemables: (
      _state: State,
      getters: Getters,
    ) => _
      .chain(getters.getShopWall || [])
      .map((v: ShopBase) => v?.redeemable)
      .flatten()
      .unique()
      .sort()
      .value(),

    /*
     * Get voucher value levels as array by shops
     * Example Output:
     * [
     *  {valueInCent: 1000, currency: 'EUR'},
     *  {valueInCent: 1500, currency: 'EUR'},
     *  ...
     * ]
     */
    getVoucherValueLevels: (
      _state: State,
      getters: Getters,
    ) => _
      .chain(getters.getShopWall)
      .map((e: ShopBase) => e?.voucherValues)
      .flatten()
      .filter((e: ShopVoucherValues) => typeof e?.valueInCent === 'number')
      .unique((e: ShopVoucherValues) => e.valueInCent)
      .sortBy((e: ShopVoucherValues) => e.valueInCent)
      .value(),

    /*
     * Getter that returns the an Array of Voucher Value Levels
     * But mapped to only the values in Cent
     */
    getVoucherValueLevelsMapped: (
      _state: State,
      getters: Getters,
    ) => () => _.map(getters.getVoucherValueLevels, (e: VoucherRedeem) => e.valueInCent),

    /*
     * Getter that returns the label for the voucher value levels (filter)
     */
    getVoucherValueLevelTitle(
      _state: State,
      getters: Getters,
    ) {
      const levels = getters?.getFilterVoucherValueLevels;
      const min = Math.min(...levels);
      const max = Math.max(...levels);
      const minLabel = getters?.getVoucherValueLevelLabel(min);
      const maxLabel = getters?.getVoucherValueLevelLabel(max);

      if (min === max) {
        return minLabel;
      }

      return `${minLabel} - ${maxLabel}`;
    },

    /*
     * Return the translated label for a voucher value level as String
     * For Example: "10 €"
     */
    getVoucherValueLevelLabel: () =>
      (voucherValueLevel: number) => n(voucherValueLevel / 100, 'currencyCompact'),
  },
};
