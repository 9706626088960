/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core';
/* import specific icons */
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Vue from 'vue';

/* add icons to the library */
library.add(faChevronDown);

/* add font awesome icon component */
Vue.component('FontAwesomeIcon', FontAwesomeIcon);

export default Vue;
