import { filter } from 'underscore';
import { ref } from 'vue';

import {
  FlashMessage,
  FlashMessageId,
} from '~model';

export default {
  messages: ref([] as unknown as FlashMessage[]),

  add(
    id: FlashMessageId,
    message: string,
    type = 'warning',
  ): void {
    this.remove(id);
    this.messages.value.push({
      id,
      type,
      message,
    });
  },

  get(
    id: FlashMessageId,
  ): FlashMessage | null {
    const flashMessage = this.messages.value.find((e) => e.id === id);

    if (flashMessage) {
      return flashMessage;
    }

    return null;
  },

  remove(
    id: FlashMessageId,
  ): void {
    this.messages.value = filter((fm: FlashMessage) => fm.id !== id);
  },
};
