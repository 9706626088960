import Vue from 'vue';
import Vuex, { Store } from 'vuex';

import config from '~module/config';
import customer from '~module/customer';
import filter from '~module/filter';
import giveaway from '~module/giveaway';
import region from '~module/region';
import router from '~module/router';
import shop from '~module/shop';
import translation from '~module/translation';
import variation from '~module/variation';
import voucher from '~module/voucher';

Vue.use(Vuex);

declare global {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface Getters {}

  type GettersDefinition<State> = {
    [index in keyof Getters]: (state?: State, getters?: Getters) => Getters[index];
  };
}

const modules = {
  config,
  variation,
  router,
  translation,
  filter,
  shop,
  voucher,
  customer,
  giveaway,
  region,
};

const store = new Vuex.Store({
  modules,
});

interface StoreModel extends Store<unknown> {
  getters: Getters;
  state: {
    config: typeof config.state;
    variation: typeof variation.state;
    translation: typeof translation.state;
    filter: typeof filter.state;
    shop: typeof shop.state;
    voucher: typeof voucher.state;
    customer: typeof customer.state;
    giveaway: typeof giveaway.state;
    region: typeof region.state;
  };
}

export default store as StoreModel;
