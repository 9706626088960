/*
 * Method for retina image file name conversion.
 * Example conversion: "header-logo.png" to "header-logo-2x.png"
 */
export function filenameRetina(filename: string): string {
  const scale = '-2x';
  const retina = filename.replace(/\.([^.]*)$/, `${scale}.$1`);
  return retina;
}

/*
 * Method for checking the existence of a logo via require resolve
 */
export function exists(src: string): boolean {
  try {
    return !!require.resolve(`~img/${src}`);
  } catch (error) {
    return false;
  }
}

/*
 * Method for checking if the src file is a valid URL
 */
export function isUrl(src: string): boolean {
  try {
    return Boolean(new URL(src));
  } catch (e) {
    return false;
  }
}

/*
 * Method for checking if the src file is local
 */
export function isLocal(src: string): boolean {
  return !isUrl(src);
}
