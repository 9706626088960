import { RemovableRef, useLocalStorage } from '@vueuse/core';
import _ from 'underscore';
import { useRoute } from 'vue-router/composables';

import api from '@/api/api';
import { useHighMaintenanceStore } from '@/store/modules/highMaintenance';
import { currentSite } from '~helper/currentSite';
import { t } from '~i18n';
import {
  Config,
  ConfigApi,
  ConfigLink,
  ConfigSite,
  PaymentOption,
  PaymentOptionPayPal,
  PaymentOptionUnzer,
  VoucherRedeemTokenPayload,
  ZendeskInfo,
} from '~model';

interface State {
  config: Config;
  category: RemovableRef<string | null> | string | null;
}

declare global {
  interface Getters {
    getConfig: Config;
    getConfigId: string;
    getConfigFlags: string[];
    hasConfigFlag: (flag: string) => boolean;
    getConfigLanguages: string[];
    getConfigLocales: [];
    getConfigHasMultipleLanguages: boolean;
    getCategory: string;
    getCategoryName: string;
    getCategoryClass: string;
    getAppClass: string;
    getVoucherCategoryIdFromConfig: number;
    getVoucherTokenPayload: VoucherRedeemTokenPayload;
    getConfigDistribution: string;
    getNumberOfShops: number;
    getShowNavBar: boolean;
    getShowShopFilterBar: boolean;
    getCountry: string;
    getShowMskPromo: boolean;
    getShowVoucherInfoModal: boolean;
    getWallHeadingTranslation: string;
    getNavbarLinks: ConfigSite['navbarLinks'];
    getB2BLinks: ConfigSite['b2bLinks'];
    getBrand: string;
    getMetaTitle: string;
    getMetaDescription: string;
    getTitle: string | false;
    getText: string | false;
    getFooterLinks: ConfigLink[];
    getPaymentOptions: PaymentOption[];
    getAmazonPay: Config['amazonPay'];
    getPayPalPayment: PaymentOptionPayPal;
    getPayPalClientId: PaymentOptionPayPal['clientId'];
    getUnzerPayment: PaymentOptionUnzer;
    getUnzerPublicKey: PaymentOptionUnzer['publicKey'];
    getShowRechargeCheckbox: boolean;
    getShowMerchantCodeRedirect: boolean;
    getShowMerchantCodePdfDownload: boolean;
    getGoogleTagManagerId: string;
    getCookieFirstSiteKey: string;
    getMaintenanceMode: boolean;
    getBaseLogoImage: string;
    getBaseLogoImageSvg: string;
    getBaseLogoImagePng: string;
    getBaseLogoRoute: string;
    getKeyVisualImage: string;
    getHowToRedeemImage: string;
    getImageFromLocal: (file: string) => string;
    getSupportData: Config['support'];
    getBrandingColor: string;
    shadeColor: (color: string, percent: number) => string;
    getRootVariables: string;
    getExternalCssFile: string;
    getFaviconPath: string;
    getZendeskInfo: ZendeskInfo;
    getCopyright: string;
    getShowApiBanner: boolean;
    getShowBrandInVault: boolean;
    getShowChargeableVoucherOrder: boolean;
    getShowCustomerSurvey: boolean;
    getShowCustomerInquiry: boolean;
    getShowGiveaway: boolean;
    getShowHowToRedeem: boolean;
    getShowKeyVisualText: boolean;
    getShowShopPromotion: boolean;
    getShowUseDataConfirmation: boolean;
    getShowVariation: boolean;
    getThankYouText: string;
    getRedeemCampaign: string;
    getRedeemValueChooseable: boolean;
    getShowInfoBeforeRedeemButton: boolean;
    getShowInfoAfterRedeemButton: boolean;
    getExternalRatingButtons: ConfigLink[];
    getCaptchaActive: boolean;
    getCaptchaSiteKey: string;
    getShowShopNameOnShopWall: boolean;
    getOverrideApiErrorMessageHtml: string;
  }
}

export default {
  state: <State>{
    /*
     * Site config from current site
     */
    config: <Config>{
      ...currentSite,
      apiConfigFinished: false,
    },

    /*
     * The current category from session storage as simple string "kids" or "fashion" etc.
     */
    category: useLocalStorage('category', null),
  },

  actions: {
    initApiConfig: ({
      getters,
      state,
    }: {
      getters: Getters;
      state: State;
    }) => {
      api
        .get(`/v2/redeem/site-config/${getters.getCountry}`)
        .then((res) => {
          state.config = {
            ...state.config,
            ...res.data,
          };
        })
        .catch((error) => {
          useHighMaintenanceStore().setHighMaintenance(error);
        })
        .then(() => {
          state.config.apiConfigFinished = true;
        });
    },

    /*
     * Load an external CSS file.
     *
     * If external CSS file defined in sites configuration,
     * append it to the HTML-header.
     */
    initExternalCssFile: ({ getters }: { getters: Getters }) => {
      const externalCssFile = getters?.getExternalCssFile;

      if (externalCssFile) {
        const style = document.createElement('link');
        style.type = 'text/css';
        style.rel = 'stylesheet';
        style.href = externalCssFile;
        document.head.appendChild(style);
      }
    },

    /*
     * Set the current Category to a given string ("name").
     */
    setCategory({ state }: { state: State }, name: string) {
      state.category = name;
    },

    /*
     * Removes the current Category and set it to null.
     */
    removeCategory({ state }: { state: State }) {
      state.category = null;
    },
  },

  getters: <GettersDefinition<State>>{
    /*
     * Getter: Get config object from state.
     */
    getConfig: (state: State, getters: Getters) => ({
      ...state.config,
      ...(<object>getters.getVariationConfig),
    }),

    /*
     * Get the configuration id (should be the currentSite).
     */
    getConfigId: (_state: State, getters: Getters) => getters.getConfig?.id,

    /*
     * Getter: Get config flags as array
     */
    getConfigFlags: (_state: State, getters: Getters) => getters.getConfig?.flags,

    /*
     * Getter: Get config flags as array
     */
    hasConfigFlag: (_state: State, getters: Getters) => (flag: string) => getters.getConfigFlags?.includes(flag),

    /*
     * Get the users language.
     */
    getConfigLanguages: (_state: State, getters: Getters) => getters?.getConfig?.languages,

    /*
     * Get the site/config locales.
     * Map the config language array with the config country for each locale using underscore.
     */
    getConfigLocales: (_state: State, getters: Getters) => _
      .map(getters?.getConfigLanguages, (lang: string) => `${lang}-${getters.getCountry}`),

    /*
     * Get if multiple languages are enabled on this site/config.
     */
    getConfigHasMultipleLanguages: (_state: State, getters: Getters) => getters?.getConfigLanguages?.length > 1,

    /*
     * Get Category.
     */
    getCategory: (state: State) => state.category,

    /*
     * The name of the category.
     */
    getCategoryName: (_state: State, getters: Getters) => getters.getConfig?.name,

    /*
     * The category class around the title.
     */
    getCategoryClass: (_state: State, getters: Getters) => (getters.getCategory ? `category-color-${getters.getCategory}` : ''),

    /*
     * The wrapper class around the whole app
     */
    getAppClass: (_state: State, getters: Getters) => `category-${getters.getCategory ?? 'base'}`,

    /*
     * Get the voucher category id from config.
     */
    getVoucherCategoryIdFromConfig: (_state: State, getters: Getters) => getters.getConfig?.voucherCategory,

    /*
     * Get the distribution from the config.
     */
    getConfigDistribution: (_state: State, getters: Getters) => getters.getConfig?.distribution,

    /*
     * Actual number of shops for translation text.
     */
    getNumberOfShops: (_state: State, getters: Getters) => getters.getConfig?.numberOfShops,

    /*
     * State if the nav-bar below the logo is visible.
     * Only if the sites.js entry (of variation response) has config-flag "showNavBar"
     * and the high-maintenance mode is not activated.
     */
    getShowNavBar: (_state: State, getters: Getters) =>
      getters.hasConfigFlag('showNavBar') &&
      useRoute().meta?.showNavBar !== false &&
      !useHighMaintenanceStore().getHighMaintenanceIsActive,

    /*
     * State if the filter-bar above the shop-wall is visible.
     */
    getShowShopFilterBar: (_state: State, getters: Getters) => !!getters.hasConfigFlag('showShopFilterBar'),

    /*
     * Get the country from the config settings.
     */
    getCountry: (_state: State, getters: Getters) => getters.getConfig?.country,

    /*
     * Get the Modal status of the mask promo modal.
     */
    getShowMskPromo: (_state: State, getters: Getters) => {
      const voucherPayload = getters.getVoucherTokenPayload;

      return (
        getters.hasConfigFlag('showMskPromo') &&
        !('thirdPartyRedeem' in voucherPayload) &&
        !(voucherPayload.voucher?.noMarketplaces)
      );
    },
    /*
     * Get the Modal status of the voucher info modal.
     */
    getShowVoucherInfoModal: (_state: State, getters: Getters) =>
      getters.hasConfigFlag('showVoucherInfoModal'),

    /*
     * Get the Wall Heading Translation (h2 title) above the shop-wall.
     */
    getWallHeadingTranslation(_state: State, getters: Getters) {
      switch (getters.getCategory) {
        case 'charity':
          return 'redeem.landingPage.AidOrga';

        default:
          return 'wgs.std.TopShops';
      }
    },

    /*
     * Get the B2B links as object
     */
    getB2BLinks: (_state: State, getters: Getters) => getters.getConfig?.b2bLinks,

    /*
     * Get the Navigation links as object
     */
    getNavbarLinks: (_state: State, getters: Getters) => getters.getConfig?.navbarLinks,

    /*
     * Get the brand from config.
     */
    getBrand: (_state: State, getters: Getters) => getters.getConfig?.brand,

    /*
     * Get the Meta-Title for the whole page.
     * If the site name and category both exists AND are not the same, split by pipe
     * Otherwise just show the site name to uppercase
     */
    getMetaTitle: (_state: State, getters: Getters) => {
      if (!getters.getConfig?.customMeta) {
        const siteName = getters.getConfig?.siteName;
        const categoryName = getters.getCategoryName;

        if (siteName !== categoryName && siteName && categoryName) {
          return `${siteName.toUpperCase()} | ${categoryName}`;
        }
        return siteName.toUpperCase();
      }
      return getters.getConfig?.customMeta?.title;
    },

    /*
     * Get the Meta-Description for the whole page if its defined in sites.js
     */
    getMetaDescription: (_state: State, getters: Getters) => getters.getConfig?.customMeta?.description,

    /*
     * Get the intro title (heading-text) for the first redeem landing-page (base-url).
     * On Category / Variation, display the heading prop. Fallback value is the i18n translated default string.
     */
    getTitle: (_state: State, getters: Getters) =>
      getters.getConfig?.heading ??
      t('vouchercategory.theWayToYourWishVoucher'),

    /*
     * Get the html-content for the enter-voucher manual/guidance as ordered list (bullet points).
     */
    getText: (_state: State, getters: Getters) => getters.getConfig?.text,

    /*
     * Get the Footer links as array of objects.
     */
    getFooterLinks: (_state: State, getters: Getters) => getters.getConfig?.footerLinks,

    getPaymentOptions: (
      _state: State,
      getters: Getters,
    ) => getters.getConfig?.payment,

    /*
     * Get the Amazon pay config entry from sites.
     */
    getAmazonPay: (_state: State, getters: Getters) => getters.getConfig?.amazonPay,

    getPayPalPayment: (
      _state: State,
      getters: Getters,
    ) => getters.getPaymentOptions.find((payment) => payment.name === 'paypal'),

    getPayPalClientId: (
      _state: State,
      getters: Getters,
    ) => getters.getPayPalPayment?.clientId,

    getUnzerPayment: (
      _state: State,
      getters: Getters,
    ) => getters.getPaymentOptions.find((payment) => payment.name === 'unzer'),

    getUnzerPublicKey: (
      _state: State,
      getters: Getters,
    ) => getters.getUnzerPayment?.publicKey,
    /*
     * Get the boolean state if recharge checkbox is enabled.
     */
    getShowRechargeCheckbox: (_state: State, getters: Getters) => getters.getConfig?.showRechargeCheckbox,

    /*
     * Get the boolean state if show merchant code redirect checkbox is enabled.
     */
    getShowMerchantCodeRedirect: (_state: State, getters: Getters) => getters.getConfig?.showMerchantCodeRedirect,

    /*
     * Get the boolean state if show merchant code pdf download is enabled.
     */
    getShowMerchantCodePdfDownload: (_state: State, getters: Getters) => getters.getConfig?.showMerchantCodePdfDownload,

    /*
     * Get google tag-manager id as string.
     */
    getGoogleTagManagerId: (_state: State, getters: Getters) => getters.getConfig.gtmId,

    /*
     * Get the cookie first site key.
     */
    getCookieFirstSiteKey: (_state: State, getters: Getters) => getters.getConfig?.cookieFirstSiteKey,

    // Get the maintenance status of the site. should be false by default.
    getMaintenanceMode: (
      _state: State,
      getters: Getters,
    ) => getters.getConfig.maintenanceMode === true || false,

    /*
     * Get the base logo image as source (filename).
     * Without it s filetype
     */
    getBaseLogoImage: (_state: State, getters: Getters) => {
      const partner = getters.getVoucherPartner;
      const logo = getters.getConfig?.logo;

      return `logos/logo-${_([logo, partner]).filter().join('-')}`;
    },

    /*
     * Get the base logo image as SVG
     */
    getBaseLogoImageSvg: (_state: State, getters: Getters) => `${getters.getBaseLogoImage}.svg`,

    /*
     * Get the base logo image as PNG
     */
    getBaseLogoImagePng: (_state: State, getters: Getters) => `${getters.getBaseLogoImage}.png`,

    /*
     * Get the base logo route.
     */
    getBaseLogoRoute: (_state: State, getters: Getters) =>
      (getters.getCategory ? `/${getters.getCategory}` : `${getters.getConfig?.website}`),
    /*
     * Get key visual image as filepath or url
     */
    getKeyVisualImage: (_state: State, getters: Getters) =>
      getters.getConfig?.keyVisualImage ??
      getters.getImageFromLocal(`keyvisual_${getters.getLanguage}.jpg`),

    /*
     * Get how to redeem instructions image as filepath or url
     */
    getHowToRedeemImage: (_state: State, getters: Getters) =>
      getters.getConfig?.instructionsImage ??
      getters.getImageFromLocal(`how-to-redeem_${getters.getLanguage}.jpg`),

    /*
     * Get a image from local (folder by config-id)
     */
    getImageFromLocal: (_state: State, getters: Getters) =>
      (file: string) => _.compact(['sites', getters.getConfigId, file]).join('/'),

    /*
     * Get the Support-URL as a string
     * example: "https://www.wunschgutschein.at/kundenservice/kontakt"
     */
    getSupportData: (_state: State, getters: Getters) => getters.getConfig?.support,

    /*
     * Get the current brand color as hex-string
     */
    getBrandingColor: (_state: State, getters: Getters) => getters.getConfig?.brandingColor ?? '#d32b2d',

    /*
     * Shade the color by amount
     */
    shadeColor: () =>
      /*
             */
      (color: string, percent: number) => {
        // If hex is too short. Like '#000' or '#123'
        if (color.length === 4) {
          // Double the hex color params like '#000000'
          color = color.split(/#/s).map((h) => `${h}${h}`).join('#');
        }

        return `#${color
          .replace(/^#/, '')
          .replace(/../g, (rgb) => (`0${Math.min(255, Math.max(0, parseInt(rgb, 16) + percent)).toString(16)}`).slice(-2))}`;
      },

    /*
     * The main root variables.
     * Changing the primary color with lighter and dark colors for each brand.
     */
    getRootVariables: (_state: State, getters: Getters) => {
      const color = getters.getBrandingColor;
      const variables = {
        '--primary': color,
        '--primary-light': getters.shadeColor(color, 15),
        '--primary-lighter': getters.shadeColor(color, 35),
        '--primary-dark': getters.shadeColor(color, -25),
        '--primary-half-transparent': `${color}80`,
        '--primary-60-transparent': `${color}60`,
        '--primary-75-transparent': `${color}40`,
        '--primary-80-transparent': `${color}20`,
      };

      return `:root {${_.map(variables, (hexColor: string, variable: string) => `${variable}: ${hexColor};\n`).join('')}}`;
    },

    /*
     * Get the custom CSS file
     */
    getExternalCssFile: (_state: State, getters: Getters) => getters.getConfig?.externalCssFile,

    /*
     * Get the favicon path from config.
     */
    getFaviconPath: (_state: State, getters: Getters) => `/favicon/${getters.getConfig?.faviconPath || 'wgs'}`,

    /*
     * Get Zendesk Info from config.
     */

    getZendeskInfo: (_state: State, getters: Getters) => getters.getConfig?.zendesk,

    /*
     * Get the copyright note in the footer component.
     */
    getCopyright: (_state: State, getters: Getters) => {
      const year = (new Date().getFullYear());
      const text = getters.getConfig?.copyright || t('wgs.std.productOfDigitalWishes');

      return `&copy; ${year} ${text}`;
    },

    /*
     * Get the boolean if the show api banner is enabled / visible.
     */
    getShowApiBanner: (_state: State, getters: Getters) => getters.getConfig?.showApiBanner,

    /*
     * Get the boolean if the show brand badge is enabled / visible in vault.
     */
    getShowBrandInVault: (_state: State, getters: Getters) => getters.getConfig?.showBrandInVault,

    /*
     * Get the boolean if the show chargeable voucher order is enabled / visible.
     */
    getShowChargeableVoucherOrder: (_state: State, getters: Getters) => getters.getConfig?.showChargeableVoucherOrder,

    /*
     * Get the boolean if the show customer survey is enabled / visible.
     */
    getShowCustomerSurvey: (_state: State, getters: Getters) => getters.getConfig?.showCustomerSurvey,

    /*
     * Get the boolean if the show is new shop customer inquiry is enabled / visible.
     */
    getShowCustomerInquiry: (_state: State, getters: Getters) => getters.getConfig?.showCustomerInquiry,

    /*
     * Get the boolean if the show giveaway is enabled / visible.
     */
    getShowGiveaway: (_state: State, getters: Getters) => getters.getConfig?.showGiveaway,

    /*
     * Get the boolean if the show how to redeem instructions image is enabled / visible.
     */
    getShowHowToRedeem: (_state: State, getters: Getters) => getters.getConfig?.showHowToRedeem,

    /*
     * Get the boolean if the show key visual text is enabled / visible.
     * Only visible on non-category site-view.
     */
    getShowKeyVisualText: (_state: State, getters: Getters) => getters.getConfig?.showKeyVisualText && !getters.getCategory,

    /*
     * Get the boolean if the shop promotion is enabled.
     * Only visible on non-category site-view.
     */
    getShowShopPromotion: (_state: State, getters: Getters) =>
      getters.getConfig?.showShopPromotion &&
      !getters.getCategory,

    /*
     * Get the boolean if the show use data confirmation checkbox is enabled.
     */
    getShowUseDataConfirmation: (_state: State, getters: Getters) => getters.getConfig?.showUseDataConfirmation,

    /*
     * Get the boolean if the show variation is enabled and
     * the variation config should be fetched via api.
     */
    getShowVariation: (_state: State, getters: Getters) => getters.getConfig?.showVariation,

    /*
     * Get the thank you text as a string (html)
     */
    getThankYouText: (_state: State, getters: Getters) => getters.getConfig?.thankYouText,

    /*
     * Get the redeem campaign like 'nissan'
     * or 'renault' as a string or null.
     */
    getRedeemCampaign: (_state: State, getters: Getters) => getters.getConfig?.redeemCampaign || null,

    /*
     * Get the boolean if the redeem value is chooseable.
     */
    getRedeemValueChooseable: (_state: State, getters: Getters) => !!getters.getConfig?.redeemValueChooseable,

    /*
     * Get the boolean if info text before the redeem button should be shown.
     */
    getShowInfoBeforeRedeemButton: (_state: State, getters: Getters) => !!getters.getConfig?.showInfoBeforeRedeemButton,

    /*
     * Get the boolean if info text after the redeem button should be shown.
     */
    getShowInfoAfterRedeemButton: (_state: State, getters: Getters) => !!getters.getConfig?.showInfoAfterRedeemButton,

    /*
     * Get the boolean if shop name should be shown on shop wall below shop logo.
     */
    getShowShopNameOnShopWall: (_state: State, getters: Getters) => !!getters.getConfig?.showShopNameOnShopWall,

    /*
     * Get the custom error message to show when api errors are overridden
     */
    getOverrideApiErrorMessageHtml: (_state: State, getters: Getters) => getters.getConfig?.overrideApiErrorMessageHtml,

    /*
     * Get the external rating buttons.
     * External rating buttons containing objects (with a link and title as strings).
     */
    getExternalRatingButtons: (_state: State, getters: Getters) => getters.getConfig?.externalRatingButtons,

    getCaptchaActive: (
      _state: State,
      getters: Getters,
    ): boolean => getters.getConfig?.captcha?.active ?? true,

    getCaptchaSiteKey: (
      _state: State,
      getters: Getters,
    ): string => String(getters.getConfig?.captcha?.siteKey),
  },
};
