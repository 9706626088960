import { RemovableRef, useLocalStorage } from '@vueuse/core';
import { AxiosError, AxiosResponse } from 'axios';
import { Commit, Dispatch } from 'vuex/types/index.d';

import api from '~api/api';
import { Variation } from '~model';

interface State {
  variation: Variation | RemovableRef<Variation>;
  loading: boolean;
}

declare global {
  interface Getters {
    getVariation: Variation;
    getVariationUrl: string;
    getVariationConfig: Variation;
    getVariationLoading: boolean;
  }
}

export default {
  state: <State> {
    /*
     * The current variation (try to get it from local storage)
     */
    variation: useLocalStorage('variation', {} as Variation),

    /*
     * Loading indicator for variation
     */
    loading: false,
  },

  actions: {
    /*
     * Init config and load flavours from API.
     * If category-gifts exist, get the config and merge it.
     * Prioritize the category-config.
     */
    async fetchVariationConfig(
      {
        state,
        dispatch,
        getters,
      }: {
        state: State;
        dispatch: Dispatch;
        getters: Getters;
      },
      path: string,
    ) {
      dispatch('addRoute');

      if (getters.getVariationUrl !== path) {
        state.loading = true;
      }

      return api
        .get(`/v2/redeem/variation/${getters.getCountry}/${path}`.toLowerCase())
        .then((response: AxiosResponse) => {
          state.variation = response?.data;
          dispatch('addRoute');
        })
        .catch((error: AxiosError) => error)
        .then(() => {
          state.loading = false;
        });
    },
  },

  getters: <GettersDefinition<State>> {
    /*
     * Get the variation from state
     */
    getVariation: (state: State) => state.variation,

    /*
     * Get the variation url from state
     */
    getVariationUrl: (
      state: State,
    ) => (state.variation as Variation)?.urlName,

    /*
     * Get the variation config as object.
     * Only by urlName matching the current category.
     */
    getVariationConfig: (
      _state: State,
      getters: Getters,
    ) =>
      (getters.getVariationUrl === getters.getCategory ? getters.getVariation : null),

    /*
     * Get the current config loading state.
     */
    getVariationLoading: (state: State) => state.loading,
  },
};
