import { defineStore } from 'pinia';

export const useHighMaintenanceStore = defineStore('highMaintenance', {
  state: () => ({
    active: false,
    info: <null | Error | string>null,
  }),

  actions: {
    /*
     * Set high maintenance to active with information.
     */
    setHighMaintenance(
      info: Error | string | null,
    ) {
      this.active = true;
      this.info = info;

      // eslint-disable-next-line no-console
      console.error('High Maintenance mode activated:', info);
    },

    /*
     * Remove high maintenance mode and information.
     */
    removeHighMaintenance() {
      this.active = false;
      this.info = null;
    },
  },

  getters: {
    /*
     * True or false if the high maintenance mode is activated.
     * Only activated if "active" is set to true and any information is set to the vuex states.
     */
    getHighMaintenanceIsActive(): boolean {
      return this.active === true && this.info !== null;
    },
  },
});

export default {};
