import axios from 'axios';
import {
  Map,
  Marker,
} from 'maplibre-gl';

import { MaptilerGeoSearchResponse, ShopId } from '~model';

interface State {
  shopId: ShopId | null;
  shopModal: boolean;
  map: Map | null;
  markers: Marker[] | null;
  search: string;
  searchLoading: boolean;
  searchResponse: MaptilerGeoSearchResponse;
}

declare global {
  interface Getters {
    getMap: Map;
    getMarkers: Marker[];
    getMapSearch: string;
  }
}

export default {
  state: <State>{
    map: null,
    markers: null,
    shopId: null,
    shopModal: false,
    search: '',
    searchLoading: false,
    searchResponse: {},
  },

  getters: <GettersDefinition<State>>{
    getMap: (state: State) => state.map,
    getMarkers: (state: State) => state.markers,
    getMapSearch: (state: State) => state.search,
  },

  actions: {
    initMaptiler: async ({ state }: { state: State }) => {
      state.map = new Map({
        container: 'map', // container's id or the HTML element to render the map
        // style: MapStyle.DATAVIZ.LIGHT,
        // style: 'https://api.maptiler.com/maps/0d23b8ab-18c4-4be1-898f-7443350d3f16/style.json',
        style: `https://api.maptiler.com/maps/a8fbb57e-5345-4917-a268-b8b2af8c4578/style.json?key=${process.env.VUE_APP_MAP_TILER_API_KEY}`,
        // center: [7.012618, 51.248873], // starting position [lng, lat]
        zoom: 1, // starting zoom
        maxBounds: [
          [5.52, 47.16],
          [15.2, 54.54],
        ],
      });

      state.markers = [];
    },

    fetchGeoCoding: (
      {
        state,
        getters,
      }: {
        state: State;
        getters: Getters;
      },
    ) => {
      state.searchLoading = true;

      axios
        .get(
          `https://api.maptiler.com/geocoding/${state.search}.json`,
          {
            params: {
              language: getters.getLanguage,
              country: getters.getCountry,
              fuzzyMatch: true,
              limit: 3,
              key: process.env.VUE_APP_MAP_TILER_API_KEY,
            },
          },
        )
        .then((response) => {
          state.searchResponse = response.data;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          state.searchLoading = false;
        });
    },
  },
};
