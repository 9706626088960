import Vue from 'vue';
import VueSanitize from 'vue-sanitize';

Vue.use(VueSanitize, {
  allowedTags: [
    // List Elements
    'ol',
    'ul',
    'li',

    // Strong
    'strong',
    'b',

    // Paragraphs and line breaks
    'p',
    'br',

    // Italic
    'i',

    // Font Element
    'font',

    // Inline Text
    'span',
  ],
  allowedAttributes: {
    font: ['style', 'color'],
    span: ['class'],
  },
});

export default Vue;
