export default {
  'de-DE': {
    currency: {
      style: 'currency',
      currency: 'EUR',
      currencyDisplay: 'symbol',
      maximumFractionDigits: 2,
    },
    currencyCompact: {
      style: 'currency',
      currency: 'EUR',
      currencyDisplay: 'symbol',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    },
    numeric: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      thousandsSeparator: '.',
      decimalPoint: ',',
    },
  },
  'de-AT': {
    currency: {
      style: 'currency',
      currency: 'EUR',
      currencyDisplay: 'symbol',
      maximumFractionDigits: 2,
    },
    currencyCompact: {
      style: 'currency',
      currency: 'EUR',
      currencyDisplay: 'symbol',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    },
    numeric: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      thousandsSeparator: '.',
      decimalPoint: ',',
    },
  },
  'de-CH': {
    currency: {
      style: 'currency',
      currency: 'CHF',
      maximumFractionDigits: 2,
    },
    currencyCompact: {
      style: 'currency',
      currency: 'CHF',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    },
    numeric: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      thousandsSeparator: "'",
      decimalPoint: ',',
    },
  },
  'fr-CH': {
    currency: {
      style: 'currency',
      currency: 'CHF',
      maximumFractionDigits: 2,
    },
    currencyCompact: {
      style: 'currency',
      currency: 'CHF',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    },
    numeric: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      thousandsSeparator: "'",
      decimalPoint: ',',
    },
  },
  'en-CH': {
    currency: {
      style: 'currency',
      currency: 'CHF',
      maximumFractionDigits: 2,
    },
    currencyCompact: {
      style: 'currency',
      currency: 'CHF',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    },
    numeric: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      thousandsSeparator: "'",
      decimalPoint: ',',
    },
  },
  'it-CH': {
    currency: {
      style: 'currency',
      currency: 'CHF',
      maximumFractionDigits: 2,
    },
    currencyCompact: {
      style: 'currency',
      currency: 'CHF',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    },
    numeric: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      thousandsSeparator: "'",
      decimalPoint: ',',
    },
  },
  'it-IT': {
    currency: {
      style: 'currency',
      currency: 'EUR',
      currencyDisplay: 'symbol',
      maximumFractionDigits: 2,
    },
    currencyCompact: {
      style: 'currency',
      currency: 'EUR',
      currencyDisplay: 'symbol',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    },
    numeric: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      thousandsSeparator: ' ',
      decimalPoint: ',',
    },
  },
  'fr-FR': {
    currency: {
      style: 'currency',
      currency: 'EUR',
      currencyDisplay: 'symbol',
      maximumFractionDigits: 2,
    },
    currencyCompact: {
      style: 'currency',
      currency: 'EUR',
      currencyDisplay: 'symbol',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    },
    numeric: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      thousandsSeparator: '.',
      decimalPoint: ',',
    },
  },
  'pl-PL': {
    currency: {
      style: 'currency',
      currency: 'PLN',
      maximumFractionDigits: 2,
    },
    currencyCompact: {
      style: 'currency',
      currency: 'PLN',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    },
    numeric: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      thousandsSeparator: ' ',
      decimalPoint: ',',
    },
  },
  'en-GB': {
    currency: {
      style: 'currency',
      currency: 'GBP',
      maximumFractionDigits: 2,
    },
    currencyCompact: {
      style: 'currency',
      currency: 'GBP',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    },
    numeric: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      thousandsSeparator: ',',
      decimalPoint: '.',
    },
  },
  'en-US': {
    currency: {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 2,
    },
    currencyCompact: {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    },
    numeric: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      thousandsSeparator: ',',
      decimalPoint: '.',
    },
  },
};
