import { AxiosError, AxiosResponse } from 'axios';
import { Dispatch } from 'vuex/types/index.d';

import api from '~api/api';
import {
  Giveaway,
  GiveawayId,
  GiveawayRedeemId,
} from '~model';

interface State {
  giveaway: Giveaway | null;
  error: Error | null;
  redeemId: GiveawayRedeemId | null;
  sending: boolean;
}

declare global {
  interface Getters {
    getGiveaway: Giveaway;
    getGiveawayId: GiveawayId;
    getGiveawayExists: boolean;
    getGiveawayVisible: boolean;
    getGiveawayBanner: string;
    getGiveawayText: string;
    getGiveawayNeedsCodes: boolean;
    getGiveawayError: Error | object;
    getRedeemId: GiveawayRedeemId;
    getGiveawaySending: boolean;
  }
}

export default {
  state: <State> {
    /* Giveaway response object from API-Call */
    giveaway: <Giveaway | null>null,

    /* Error states on wrong user-input */
    error: <Error | null>null,

    /* Redeem-id from voucher-token response */
    redeemId: <number | null>null,

    /* indicates if the email for the giveaway is sending */
    sending: false,
  },

  actions: {
    /*
     * Fetch a giveaway through api-call and set the
     * response giveaway object to state
     */
    async fetchGiveaway(
      {
        state,
        dispatch,
        getters,
      }: {
        state: State;
        dispatch: Dispatch;
        getters: Getters;
      },
    ) {
      const voucher = getters.getVoucher;
      const token = getters.getVoucherToken;
      const redeemId = getters?.getRedeemId;
      const headers = {
        'x-jwt-token': token,
      };

      return api
        .post('/v2/giveaway/get-one-active-with-unused-codes', { voucher, redeemId }, { headers })
        .then((response: AxiosResponse) => {
          state.giveaway = response?.data;

          if (state.giveaway) {
            state.giveaway.visible = true;
          }

          if (!getters.getGiveawayExists) {
            dispatch('removeCurrentVoucherAndToken');
          }
        }).catch((error: AxiosError) => error);
    },

    /*
     * Claim a giveaway by sending email
     */
    async redeemGiveaway(
      {
        state,
        dispatch,
        getters,
      }: {
        state: State;
        dispatch: Dispatch;
        getters: Getters;
      },
    ) {
      const voucher = getters.getVoucher;
      const token = getters.getVoucherToken;
      const email = getters?.getUserEmail;
      const redeemId = getters?.getRedeemId;
      const id = getters?.getGiveawayId;
      const headers = {
        'x-jwt-token': token,
      };

      state.sending = true;

      return api
        .post('/v2/giveaway/redeem-advertisement-popup-code', {
          voucher, email, id, redeemId,
        }, { headers })
        .then(() => {
          dispatch('removeCurrentVoucherAndToken');
          state.giveaway = null;
          state.error = null;
        })
        .catch((error: AxiosError) => {
          state.error = error?.response?.data?.errors || error;
        })
        .then(() => {
          state.sending = false;
        });
    },

    /*
     * Hide the Giveaway modal
     */
    hideGiveaway({ state }: { state: State }) {
      if (state.giveaway) {
        state.giveaway.visible = false;
      }
    },

    /*
     * Remove the Giveaway modal
     */
    removeGiveaway({ state }: { state: State }) {
      state.giveaway = null;
      state.error = null;
    },

    /*
     * Set the redeem-id from voucher-token-response to vuex state.
     */
    setRedeemIdForGiveaway(
      { state }: { state: State },
      redeemId: GiveawayRedeemId,
    ) {
      state.redeemId = redeemId;
    },
  },

  getters: <GettersDefinition<State>> {
    /*
     * Get the Giveaway from giveaway response state.
     */
    getGiveaway: (state: State) => state.giveaway,

    /*
     * Get the Giveaway ID from giveaway response state.
     */
    getGiveawayId: (state: State) => state.giveaway?.id,

    /*
     * Get status if the giveaway exists by checking ID
     */
    getGiveawayExists: (
      _state: State,
      getters: Getters,
    ) => !!getters.getGiveawayId,

    /*
     * Show the giveaway modal only on visible state true
     * and with existing giveaway-id.
     */
    getGiveawayVisible: (
      state: State,
      getters: Getters,
    ) => getters.getGiveawayExists && state.giveaway?.visible,

    /*
     * Get the Giveaway Banner from giveaway response state.
     */
    getGiveawayBanner: (state: State) => state.giveaway?.banner,

    /*
     * Get the Giveaway Text (HTML) from giveaway response state.
     */
    getGiveawayText: (state: State) => state.giveaway?.promoText,

    /*
     * Get the Giveaway needs code state from giveaway response state.
     */
    getGiveawayNeedsCodes: (state: State) => state.giveaway?.needsCodes,

    /*
     * Get the Giveaway error from state.
     */
    getGiveawayError: (state: State) => state.error,

    /*
     * Get the redeem-id from state.
     */
    getRedeemId: (state: State) => state.redeemId,

    /*
     * Get the status for sending the giveaway email.
     */
    getGiveawaySending: (state: State) => state.sending,
  },

};
