import { VueConstructor } from 'vue';

import flashMessage from './flash-message';
import FlashMessageComponent from './FlashMessage.vue';

export default {
  install(Vue: VueConstructor) {
    Vue.component('FlashMessage', FlashMessageComponent);
    Vue.prototype.$flashMessage = flashMessage;
  },
};
