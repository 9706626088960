import axios from 'axios';

declare global {
  interface XMLHttpRequest {
    url: URL | string;
  }

  interface Window {
    activeXhr: XMLHttpRequest[];
  }
}

const api = axios.create({
  baseURL: process.env.VUE_APP_API_PREFIX,
});

// Only in test env
if (parseInt(process?.env?.VUE_APP_IS_E2E_TEST, 2) === 1) {
  // Add e2e header to Axios API
  api.defaults.headers.common['x-e2e-test-secret'] = process?.env?.VUE_APP_TEST_E2E_HEADER_SECRET;

  // Window onload function
  window.onload = (): void => {
    setTimeout((): void => {
      document.body.classList.add('window-loaded');
    }, 100);
  };

  // Watch all XHR Calls for e2e tests. set body class to "ready" if every made xhr-call is finished
  ((open) => {
    window.activeXhr = [];

    XMLHttpRequest.prototype.open = function customOpen(
      method: string,
      url: URL,
      async?: boolean,
      user?: string,
      pass?: string,
    ): void {
      this.addEventListener('readystatechange', () => {
        const readyClass = 'all-xhrs-loaded';
        document.body.classList.remove(readyClass);
        this.url = url;
        switch (this.readyState) {
          case 1: {
            window.activeXhr.push(this);
            break;
          }
          case 4: {
            const i = window.activeXhr.indexOf(this);

            if (i > -1) {
              window.activeXhr.splice(i, 1);
            }

            if (window.activeXhr.length <= 0) {
              setTimeout(() => {
                document.body.classList.add(readyClass);
              }, 100);
            }
            break;
          }
          default:
            break;
        }
      }, false);
      open.call(this, method, url, async || false, user, pass);
    };
  })(XMLHttpRequest.prototype.open);
}

export default api;
