<template>
  <div class="header-logo" :class="{ 'is-mobile': mobile }">
    <b-link :href="route">
      <ImageVector
        :filename="logo"
        :fallback="fallback"
        :alt="$t('admin.nav.Wunschgutschein')"
      />
    </b-link>
    <LanguageSwitcher
      v-if="!mobile"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import ImageVector from '~widget/ImageVector';
import LanguageSwitcher from '~widget/LanguageSwitcher';

export default {
  components: {
    ImageVector,
    LanguageSwitcher,
  },

  props: {
    /* If enabled, mobile class is active and language-switcher is hidden. */
    mobile: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    ...mapGetters({
      route: 'getBaseLogoRoute',
      logo: 'getBaseLogoImageSvg',
      fallback: 'getBaseLogoImagePng',
    }),
  },
};
</script>
