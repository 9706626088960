import { Jwt } from '~model';

export default {
  decode(t: string) {
    const token = {} as Jwt;
    token.raw = t;
    token.header = JSON.parse(window.atob(t.split('.')[0]));
    token.payload = JSON.parse(window.atob(t.split('.')[1]));
    return token;
  },
};
