/* eslint-disable @typescript-eslint/no-explicit-any */
import _ from 'underscore';

import {
  Category,
  Filter,
  FilterMethod,
  Redeemables,
  ShopBase,
  ShopId,
  ShopVoucherValues,
  ShopWall,
  VoucherValueLevels,
} from '~model';

interface State {
  categories: Category[];
  voucherValueLevels: VoucherValueLevels;
  redeemable: Redeemables;
  name: string;
  shopIds: ShopId[];
}

declare global {
  interface Getters {
    getFilters: Filter;
    getFilterCategories: Category[];
    getFilterVoucherValueLevels: VoucherValueLevels;
    getFilterRedeemable: Redeemables;
    getFilterName: string;
    getFilterShopIds: ShopId[];
    anyFiltersExist: boolean;
    multiFilterChain: (shops: ShopWall, filters: FilterMethod[]) => ShopWall;
    shopHasCategories: (shop: ShopBase, categories: Category[]) => boolean;
    shopHasVoucherValueLevels: (shop: ShopBase, levels: VoucherValueLevels) => boolean;
    shopHasRedeemables: (shop: ShopBase, redeemables: Redeemables) => boolean;
    filterShopsByCategories: (shops: ShopWall, categories: Category[]) => ShopWall;
    filterShopsByVoucherValueLevels: (shops: ShopWall, levels: VoucherValueLevels) => ShopWall;
    filterShopsByRedeemables: (shops: ShopWall, redeemables: Redeemables) => ShopWall;
    filterShopsByName: (shops: ShopWall, filterName: string) => ShopWall;
    sortShopsByMapSearchName: (shops: ShopWall, mapSearch: string) => ShopWall;
    filterShopsByIds: (shops: ShopWall, filterShopIds: ShopId[]) => ShopWall;
    filteredTopShops: ShopWall;
    filteredDefaultShops: ShopWall;
    filterShopsByPosition: (shops: ShopWall, position: string) => ShopWall;
    filterShops: (shops: ShopWall) => ShopWall;
    getFilteredShops: ShopWall;
    categoryIsClickable: (category: Category[]) => boolean;
    voucherValueLevelIsClickable: (level: VoucherValueLevels) => boolean;
    redeemableIsClickable: (redeemable: string) => boolean;
    nameIsSearchable: (name: string) => boolean;
  }
}

export default {
  state: <State> {
    categories: [],
    voucherValueLevels: [],
    redeemable: [],
    name: '',
    shopIds: [],
  },

  actions: {
    setFilterName(
      {
        state,
      }: {
        state: State;
      },
      name: string,
    ): void {
      state.name = name;
    },

    /*
     * Reset each filters do default state (empty).
     */
    resetFilters(
      {
        state,
      }: {
        state: State;
      },
    ): void {
      state.categories = [];
      state.voucherValueLevels = [];
      state.redeemable = [];
      state.name = '';
      state.shopIds = [];
    },
  },

  getters: <GettersDefinition<State>> {
    /*
     * Get all filters from state.
     */
    getFilters: (state: State) => state,

    /*
     * Get filter categories from state.
     */
    getFilterCategories: (state: State) => state.categories,

    /*
     * Get filter voucher value levels from state.
     */
    getFilterVoucherValueLevels: (state: State) => state.voucherValueLevels,

    /*
     * Get filter redeemable from state.
     */
    getFilterRedeemable: (state: State) => state.redeemable,

    /*
     * Get filter name from state.
     */
    getFilterName: (state: State) => state.name?.toLowerCase()?.replace(/\s/g, ''),

    getFilterShopIds: (state: State) => state.shopIds,

    /*
     * If any filter is existing and filled
     */
    anyFiltersExist: (
      _state: State,
      getters: Getters,
    ) => _.some(getters.getFilters, (filter: []) => filter?.length > 0),

    /*
     * Filter-chain. Filter array by multiple filters using underscore
     */
    multiFilterChain: () =>
      (shops: ShopWall, filters: FilterMethod[]) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        _.each(filters, (filter: (arr: ShopWall) => ShopWall) => {
          shops = filter(shops);
        });
        return shops;
      },

    /*
     * Return boolean (status) if shop has one or more filterCategories
     * should contain any filter-category inside its own shop-categories
     * Return the value "true" or "false" if containing
     */
    shopHasCategories: () =>
      (
        shop: ShopBase,
        filterCategories: Category[],
      ) => _.some(shop?.categories, (shopCategory: string) => _.contains(filterCategories, shopCategory)),

    /*
     * Return boolean if the shop has voucher value levels
     * Each shop with voucherValues
     * Each shop with levels should contain any filter-level inside its own shop-levels
     * Return the value "true" or "false"
     */
    shopHasVoucherValueLevels: () =>
      /*
               */
      (
        shop: ShopBase,
        filterLevels: VoucherValueLevels,
      ) => _.some(shop?.voucherValues, (value: ShopVoucherValues) => _.contains(filterLevels, value.valueInCent)),

    /*
     * Return boolean if the shop has one of the redeemables
     * Each shop with redeemable(s)
     * should contain any filter-redeemable(s) inside its own shop-redeemable
     * Return the value "true" or "false"
     */
    shopHasRedeemables: () =>
      (
        shop: ShopBase,
        filterRedeemables: Redeemables,
      ) => _.some(shop?.redeemable, (redeemable: string) => _.contains(filterRedeemables, redeemable)),

    /*
     * Filter array of shops by existing categories (using underscore)
     * Should always be less or same amount of shops than before
     */
    filterShopsByCategories: (_state: State, getters: Getters) =>
      (
        shops: ShopWall,
        filterCategories = getters.getFilterCategories,
      ) => {
        if (filterCategories?.length > 0) {
          return _.filter(shops, (shop: ShopBase) => getters.shopHasCategories(shop, filterCategories));
        }
        return shops;
      },

    /*
     * Filter array of shops by the selected array of
     * voucher value levels (in cent) (using underscore)
     * for example: [1000, 2000, 2500, ...]
     */
    filterShopsByVoucherValueLevels: (_state: State, getters: Getters) =>
      /*
             */
      (
        shops: ShopWall,
        filterLevels = getters.getFilterVoucherValueLevels,
      ) => {
        if (filterLevels?.length > 0) {
          return _.filter(shops, (shop: ShopBase) => getters.shopHasVoucherValueLevels(shop, filterLevels));
        }
        return shops;
      },

    /*
     * Filter shops by redeemable using underscore
     * like REDEEMABLE_ONLINE or REDEEMABLE_BRANCH
     * If redeemable filter is set => filter every shop containing one redeemable option
     */
    filterShopsByRedeemables: (_state: State, getters: Getters) =>
      (
        shops: ShopWall,
        filterRedeemables = getters.getFilterRedeemable,
      ) => {
        if (filterRedeemables?.length > 0) {
          return _.filter(shops, (shop: ShopBase) => getters.shopHasRedeemables(shop, filterRedeemables));
        }
        return shops;
      },

    /*
     * Filter shop list by shop name (Search-Bar)
     * Search in shop-name and in shop-slug
     */
    filterShopsByName: (_state: State, getters: Getters) =>
      /*
       * Filter shop list by shop name (Search-Bar)
       * Search in shop-name and in shop-slug
       */
      (
        shops: ShopWall,
        filterName = getters.getFilterName,
      ) => {
        if (filterName.length > 0) {
          return _.chain(shops)
            .filter((shop: ShopBase) => {
              if (shop.allowNameFullTextSearch === false) {
                return shop.name === filterName || shop.slug === filterName;
              }
              return shop?.search?.includes(filterName);
            })
            .sortBy((shop: ShopBase) => shop?.search?.indexOf(filterName))
            .value();
        }

        return shops;
      },

    sortShopsByMapSearchName: (
      _state: State,
      getters: Getters,
    ) =>
      (
        shops: ShopWall,
        mapSearch = getters.getMapSearch,
      ) => {
        if (mapSearch?.length > 0) {
          const searchIndex = (
            shop: ShopBase,
          ): number => shop
            ?.search
            ?.indexOf(
              mapSearch.toLowerCase(),
            ) ?? -1;

          return shops
            .sort((a, b) => searchIndex(b) - searchIndex(a));
        }
        return shops;
      },

    filterShopsByIds: (_state: State, getters: Getters) =>
      (
        shops: ShopWall,
        shopIds = getters.getFilterShopIds,
      ) => {
        if (shopIds?.length > 0) {
          return _
            .filter(
              shops,
              (shop: ShopBase) => shopIds.includes(shop.id),
            );
        }
        return shops;
      },

    /*
     * Filtered top shops
     */
    filteredTopShops: (_state: State, getters: Getters) => {
      let shops = getters.getShopWall;
      shops = getters.filterShopsByPosition(shops, 'T');
      shops = getters.filterShops(shops);
      return shops;
    },

    /*
     * Filtered default shops if any filter exists
     */
    filteredDefaultShops: (_state: State, getters: Getters) => {
      if (!getters.anyFiltersExist) {
        return [];
      }

      let shops = getters.getShopWall;
      shops = getters.filterShopsByPosition(shops, 'S');
      shops = getters.filterShops(shops);
      return shops;
    },

    /*
     * Filter array of shops by position
     */
    filterShopsByPosition: () =>
      (
        shops: ShopWall,
        position: string,
      ) => _.filter(shops, (shop: ShopBase) => shop?.position === position),

    /*
     * Filter shops by all filters
     */
    filterShops: (_state: State, getters: Getters) =>
      (shops: ShopWall) => {
        shops = getters.multiFilterChain(shops, [
          getters.filterShopsByCategories,
          getters.filterShopsByVoucherValueLevels,
          getters.filterShopsByRedeemables,
          getters.filterShopsByName,
          getters.filterShopsByIds,
          getters.sortShopsByMapSearchName,
        ]);

        return shops;
      },

    /*
     * Get the filtered Shops
     */
    getFilteredShops: (
      _state: State,
      getters: Getters,
    ) => getters.filterShops(getters?.getShopWall),

    /*
     * Check if the category (filter entry) is clickable / selectable
     */
    categoryIsClickable: (
      _state: State,
      getters: Getters,
    ) =>
      /*
           */
      (category: Category[]) => {
        const shops = getters.multiFilterChain(getters.getShopWall, [
          getters.filterShopsByVoucherValueLevels,
          getters.filterShopsByRedeemables,
          getters.filterShopsByName,
        ]);

        return _.some(shops, (shop: ShopBase) => getters.shopHasCategories(shop, category));
      },

    /*
     * Check if the voucher value level (filter entry) is clickable / selectable
     * "vvl" alias for "VoucherValueLevel"
     */
    voucherValueLevelIsClickable: (
      _state: State,
      getters: Getters,
    ) =>
      /*
           */
      (level: VoucherValueLevels) => {
        const shops = getters.multiFilterChain(getters.getShopWall, [
          getters.filterShopsByCategories,
          getters.filterShopsByRedeemables,
          getters.filterShopsByName,
        ]);

        return _.some(shops, (shop: ShopBase) => getters.shopHasVoucherValueLevels(shop, level));
      },

    /*
     * Check if the redeemable is clickable.
     * inherited example array could be ['REDEEMABLE_ONLINE', 'REDEEMABLE_BRANCH']
     */
    redeemableIsClickable: (
      _state: State,
      getters: Getters,
    ) =>
      (redeemable: string) => {
        const shops = getters.multiFilterChain(getters.getShopWall, [
          getters.filterShopsByCategories,
          getters.filterShopsByVoucherValueLevels,
          getters.filterShopsByName,
        ]);

        return _.some(shops, (shop: ShopBase) => getters.shopHasRedeemables(shop, [redeemable] as any));
      },

    /*
     * Check if the search-term (name) is searchable in the
     * list of pre-filtered shops.
     */
    nameIsSearchable: (
      _state: State,
      getters: Getters,
    ) =>
      (name: string) => {
        let shops = getters.multiFilterChain(getters.getShopWall, [
          getters.filterShopsByCategories,
          getters.filterShopsByVoucherValueLevels,
          getters.filterShopsByRedeemables,
        ]);

        // Filter by search term
        shops = getters.filterShopsByName(shops, name);

        // If matching shops for name found
        return shops?.length > 0; // Return true or false;
      },
  },
};
